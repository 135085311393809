//LOCAL

// export const BUCKET_NAME = 'dev-kenko-mx-bucket01';
// export const REGION = 'ca-central-1';
// export const ACCESS_KEY_ID = 'AKIATLWPJOYOKXSZKRX3';
// export const SECRET_ACCESS_KEY = '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';
// export const GOOGLE_MAPS_KEY = 'AIzaSyAxtLYrVFp-osbjtPCLXas3t05C6IGoQU4';
// export const GOOGLE_GEOCODE_KEY = 'AIzaSyDuyNuWBDJYHtXluY2k4BkKSplU6NUvRP4';
// export const WS_URL = 'ws://localhost:3002/cable';
// export const SERVER = 'http://localhost:3002';
// export const SUMA_VALIDATION_ENABLE = 'false';
// export const OPENP_MERCHANT_ID = 'm0mu47puimzqwxwwycyj';
// export const OPENP_KEY = 'pk_044cfe1bb7774b3db184c158c487faa7'
// export const OPENP_SANDBOX = true



//TEST

// export const BUCKET_NAME = 'dev-kenko-mx-bucket01';
// export const REGION = 'ca-central-1';
// export const ACCESS_KEY_ID = 'AKIATLWPJOYOKXSZKRX3';
// export const SECRET_ACCESS_KEY = '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';
// export const GOOGLE_MAPS_KEY = 'AIzaSyAxtLYrVFp-osbjtPCLXas3t05C6IGoQU4';
// export const GOOGLE_GEOCODE_KEY = 'AIzaSyDuyNuWBDJYHtXluY2k4BkKSplU6NUvRP4';
// export const WS_URL = 'wss://kenko-services.herokuapp.com/cable';
// export const SERVER = 'https://kenko-services.herokuapp.com';
// export const SUMA_VALIDATION_ENABLE = 'true'
// export const OPENP_MERCHANT_ID = 'm0mu47puimzqwxwwycyj';
// export const OPENP_KEY = 'pk_044cfe1bb7774b3db184c158c487faa7'
// export const OPENP_SANDBOX = true



//STAGING

// export const BUCKET_NAME = 'staging-kenko-health-bucket01';
// export const REGION = 'ca-central-1';
// export const ACCESS_KEY_ID = 'AKIATLWPJOYOKXSZKRX3';
// export const SECRET_ACCESS_KEY = '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';
// export const GOOGLE_MAPS_KEY = 'AIzaSyAxtLYrVFp-osbjtPCLXas3t05C6IGoQU4';
// export const GOOGLE_GEOCODE_KEY = 'AIzaSyDuyNuWBDJYHtXluY2k4BkKSplU6NUvRP4';
// export const WS_URL = 'rediss://:pf0edd08da7f304408cffa691d360452063e25880d0b6630146445e5bcc818fe9@ec2-54-145-215-74.compute-1.amazonaws.com:6840';
// export const SERVER = 'https://staging.services.kenko.health';
// export const SUMA_VALIDATION_ENABLE = 'true'
// export const OPENP_MERCHANT_ID = 'm0mu47puimzqwxwwycyj';
// export const OPENP_KEY = 'pk_044cfe1bb7774b3db184c158c487faa7'
// export const OPENP_SANDBOX = true



//STAGING

// export const BUCKET_NAME = 'dev-kenko-mx-bucket01';
// export const REGION = 'ca-central-1';
// export const ACCESS_KEY_ID = 'AKIATLWPJOYOKXSZKRX3';
// export const SECRET_ACCESS_KEY = '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';
// export const GOOGLE_MAPS_KEY = 'AIzaSyAxtLYrVFp-osbjtPCLXas3t05C6IGoQU4';
// export const GOOGLE_GEOCODE_KEY = 'AIzaSyDuyNuWBDJYHtXluY2k4BkKSplU6NUvRP4';
// export const WS_URL = 'wss://staging.services.kenko.health/cable';
// export const SERVER = 'https://staging.services.kenko.health';
// export const SUMA_VALIDATION_ENABLE = 'false'
// export const OPENP_MERCHANT_ID = 'm0mu47puimzqwxwwycyj';
// export const OPENP_KEY = 'pk_044cfe1bb7774b3db184c158c487faa7'
// export const OPENP_SANDBOX = true


//PRODUCTION 

export const BUCKET_NAME = 'kenko-health-bucket01';
export const REGION = 'ca-central-1';
export const ACCESS_KEY_ID = 'AKIATLWPJOYOKXSZKRX3';
export const SECRET_ACCESS_KEY = '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';
export const GOOGLE_MAPS_KEY = 'AIzaSyAxtLYrVFp-osbjtPCLXas3t05C6IGoQU4';
export const GOOGLE_GEOCODE_KEY = 'AIzaSyDuyNuWBDJYHtXluY2k4BkKSplU6NUvRP4';
export const WS_URL = 'wss://services.kenko.health/cable';
export const SERVER = 'https://services.kenko.health';
export const SUMA_VALIDATION_ENABLE = 'true'
export const OPENP_MERCHANT_ID = 'mnmsc266mvujecmlojoo';
export const OPENP_KEY = 'pk_a8e2d8c3da834c008a8599da28635a7f'
export const OPENP_SANDBOX = false